import {useDispatch, useSelector} from "react-redux";
import {
    selectQuest, selectQuestAnswer
} from "../../entities/Quest/model/questSelectors";
import {translate} from "../../shared/utils/translator";
import React, {useEffect, useState} from "react";
import useTelegram from "../../shared/hooks/useTelegram";
import {checkQuest, getQuests, setAnswer} from "../../entities/Quest/model/questActions";
import DayTimer from "./components/DayTimer";
import "./style.css";
import {selectUserQuestConsecutiveDays} from "../../entities/User/model/userSelectors";
import CXPLogo from "../../shared/assets/images/Logo Small.jpg";
import {selectCurrentLanguage} from "../../entities/Language/model/languageSelectors";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

function getStreakMessage(quest_streak) {
    const lastDigit = quest_streak % 10; // last digit
    const lastTwoDigits = quest_streak % 100; // last two digits to process 11-19

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return translate("quest-page-streak", { quest_streak }); // "дней"
    } else if (lastDigit === 1) {
        return translate("quest-page-streak-one", { quest_streak }); // "день"
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return translate("quest-page-streak-two", { quest_streak }); // "дня"
    } else {
        return translate("quest-page-streak", { quest_streak }); // "дней"
    }
}

const QuestPage = () => {
    const {onOpenLink} = useTelegram();
    const [questDone, setQuestDone] = useState(false);
    const [questResult, setQuestResult] = useState(null);
    const dispatch = useDispatch();
    const quest = useSelector(selectQuest);
    const quest_streak = useSelector(selectUserQuestConsecutiveDays);
    const language = useSelector(selectCurrentLanguage);
    const [loading, setLoading] = useState(false);
    const answer = useSelector(selectQuestAnswer);

    useEffect(() => {
        if (answer) {
            setQuestResult(answer);
        }
    }, []);

    useEffect(() => {
        // Fetch quests if not present in the Redux store
        const fetchQuestData = async () => {
            if (!quest) {
                setLoading(true);  // Start loading
                await dispatch(getQuests());
                setLoading(false); // End loading whether success or failure
            }
        };

        fetchQuestData();
    }, [dispatch, quest]);

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }

    const quest_reward = questDone
        ? 1000 + ((quest_streak > 30 ? 30 : quest_streak) + 1) * 100
        : 1000 + (quest_streak > 30 ? 31 : quest_streak) * 100;

    const renderStreakMessage = () => {
        if (language === "ru") {
            return getStreakMessage(quest_streak);
        } else if (language === "en") {
            // Logic for english language
            return quest_streak === 1
                ? translate("quest-page-streak-one", { quest_streak }) // 1 day
                : translate("quest-page-streak", { quest_streak });    // days
        }
    };
    if (!quest) {
        return (
            <section className="quest-page-container">
                <h1 className={`quest-page-title`}>{translate('quest-page-title')}</h1>
                <div className="quest-logo-section">
                    <img src={CXPLogo} alt="CM_tasks_Logo" className="cm_tasks_logo" />
                </div>
                <p className="quest-reward-text">{translate("quest-text-tip")}</p>
                <div className="quest-page-bottom-container">
                    <p className="quest-page-reward-text">
                        {
                            !questDone ?
                                translate("quest-page-reward", {quest_reward})
                                :
                                translate("quest-page-tomorrow-reward", {quest_reward})
                        }
                    </p>
                    <p className="quest-page-streak-text">
                        {renderStreakMessage()}
                    </p>
                </div>
                <DayTimer />
            </section>
        );
    }

    const parseString = (str) => {
        // Regular expression to match content inside --
        const linkRegex = /--(.*?)--/;
        const match = str.match(linkRegex);

        // If there's a match, extract the link and replace it in the content
        const link = match ? match[1] : '';
        const content = str.replace(linkRegex, '').trim(); // Remove the link from the content

        return { link, content };
    };

    const { link, content } = parseString(quest.question);

    const handleLinkClick = () => {
        console.log("Pressed to open link: ", link);
        onOpenLink(link);
    }

    const handleAnswerButtonClick = (answer) => {
        if (questDone) {
            return;
        }
        const response = dispatch(checkQuest(quest.id, answer));
        // if (!response) {
        //     console.error("Quest error", response);
        //     return;
        // }

        dispatch(setAnswer(response));
        setQuestDone(response);
        setQuestResult(response);
    }

    return (
        <section className="quest-page-container">
            <h1 className={`quest-page-title`}>{translate('quest-page-title')}</h1>
            <div className={`quest-logo-section ${quest && "inactive"}`}>
                <img src={CXPLogo} alt="CM_tasks_Logo" className={`cm_tasks_logo`} />
            </div>
            {
                questResult &&
                <div className="quest-result">
                    <h1 className={questResult ? "quest-result-true" : "quest-result-false"}>
                        {questResult ? translate("quest-result-true") : translate("quest-result-false")}
                    </h1>
                </div>
            }

            <p className={`quest-question-text ${questResult && "disabled"}`}>{content} <br /><span className={`quest-question-text-link  ${questResult && "disabled"}`} onClick={handleLinkClick}>{translate("useful-link")}</span></p>
            <div className="quest-answer-container">
                <button className={`quest-answer-yes ${questResult && "disabled"}`} onClick={() => handleAnswerButtonClick(true)}>{translate('yes')}</button>
                <button className={`quest-answer-no ${questResult && "disabled"}`} onClick={() => handleAnswerButtonClick(false)}>{translate('no')}</button>
            </div>
            <div className="quest-page-bottom-container">
                <p className="quest-page-reward-text">
                    {
                        !questResult ?
                            translate("quest-page-reward", {quest_reward})
                            :
                            translate("quest-page-tomorrow-reward", {quest_reward})
                    }
                </p>
                <p className="quest-page-streak-text">
                    {renderStreakMessage()}
                </p>
            </div>
            <DayTimer />
        </section>
    );



///////////////////// OLD VERSION
//     const question = "Биткоин был создан в 2009 году? --https://ru.wikipedia.org/wiki/%D0%91%D0%B8%D1%82%D0%BA%D0%BE%D0%B9%D0%BD--";
//     const language = "ru";
//     const id = 100;
//
//     // TEST
//     // const quest = {
//     //     id: 0,
//     //     "author": "eduard",
//     //     "question": "Биткоин был создан в 2009 году? --https://ru.wikipedia.org/wiki/%D0%91%D0%B8%D1%82%D0%BA%D0%BE%D0%B9%D0%BD--",
//     //     "answer": true,
//     //     "locale": "ru",
//     //     "created-at": "2024-09-06T07:52:29.066Z"
//     // }
//     // const quest_streak = 5;
//
}

export default QuestPage;
