import "./style.css";
import {useSelector} from "react-redux";
import {selectUserCheckInConsecutiveDays} from "../../entities/User/model/userSelectors";
import {translate} from "../../shared/utils/translator";
import WelcomeShark from "../../shared/assets/images/WelcomeShark.jpg";
import React from "react";
function CheckInPage({handleClick}) {
    const CheckInConsecutiveDays = useSelector(selectUserCheckInConsecutiveDays);

    const renderText = () => {
        if (CheckInConsecutiveDays === 30 || CheckInConsecutiveDays === 60) {
            return translate("check-in-page-text-anniversary", {CheckInConsecutiveDays});
        }
        if (CheckInConsecutiveDays === 90) {
            return translate("check-in-page-text", {CheckInConsecutiveDays})
        }
        return translate("check-in-page-text", { CheckInConsecutiveDays });
    };

    const renderAwardText = () => {
        if (CheckInConsecutiveDays === 30) {
            return translate("check-in-page-award-text-30");
        }
        if (CheckInConsecutiveDays === 60) {
            return translate("check-in-page-award-text-60");
        }
        if (CheckInConsecutiveDays === 90) {
            return translate("check-in-page-award-text-90");
        }
        if (CheckInConsecutiveDays > 60 && CheckInConsecutiveDays < 90) {
            return translate("check-in-page-award-text-61");
        }
        if (CheckInConsecutiveDays > 30 && CheckInConsecutiveDays < 60) {
            return translate("check-in-page-award-text-31");
        }
        return translate("check-in-page-award-text");
    };
    return (
        <section className="check-in-page-container" onClick={handleClick}>
            <div className="close-button" onClick={handleClick}>
                &times;
            </div>
            <div className="welcome-image-container">
                <img className="welcome-image" src={WelcomeShark} alt=""/>
            </div>
            <h3>{renderText()}</h3>
            <h3>{renderAwardText()}</h3>
            <button className="ok-button">{translate("check-in-page-button-ok")}</button>
        </section>
    );
}

export default CheckInPage;
