import {fetchCheckQuest, fetchQuests} from "../api/questActions";
import {setUserTotalPoints} from "../../User/model/userActions";

export const SET_QUEST = 'SET_QUEST';
export const SET_ANSWER = 'SET_ANSWER';

export const setQuest = (quest) => ({
    type: SET_QUEST,
    payload: quest,
});

export const setAnswer = (answer) => ({
    type: SET_ANSWER,
    payload: answer
})

export const getQuests = () => async (dispatch) => {
    try {
        const response = await fetchQuests();
        if (response) {
            dispatch(setQuest(response));
            return true;
        }
        return false;
    } catch (e) {
        console.error('failed to get Quests:', e);
        return false;
    }
}

export const checkQuest = (questId, answer) => async (dispatch) => {
    try {
      const response = await fetchCheckQuest(questId, answer);
      console.log("checkQuest response ", response);
      if (response) {
          if (response['total-points']) {
              await dispatch(setUserTotalPoints(response['total-points']));
              return true;
          }
          return false;
      }
      return false;
    } catch (e) {
        console.log("Failed to check quest", e);
        return false;
    }
}
